import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const Hushly = () => {
  const headingbanner = {
    title: `Hushly`,
    content: `A B2B MarTech Platform`,
  };

  const data = {
    images: ["hushly-project.webp", "hushly-project-2.webp"],
    firstAlt: "UI UX Product Page For B2B Martech Platform",
    firstTitle: "Product Page",
    secondAlt: "Product Details Page For Specific Topics",
    secondTitle: "Product Details Page",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Martech"],
      },
      {
        title: "What we did",
        text: [
          "UX Consultation",
          "UI UX Designing",
          "Design System Engineering",
        ],
      },
      {
        title: "Platform",
        text: ["Responsive Website"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `The team at Hushly wanted to opt out of the traditional marketing approach. They wanted to implement the tools and techniques of the modern age marketing technology to assist B2B marketers to increase conversion and improve their customer experience. `,
          `However, they have several challenges while transforming into new martech methods. The platform has to be optimised to reduce the risk of system crashes, slow performance or unexpected errors that can impact the workflow and overall productivity of the team.`,
          `Another problem with their platform is the integration challenges. Being a MarTech platform, they needed to integrate with various other tools and systems, such as CRM software, email marketing platforms, analytics tools, etc. to avoid data synchronisation issues, duplication of efforts and incomplete or inaccurate insights.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `With our solutions, we are assisting them to provide customer-focused modern solutions to increase the conversion rate of B2B marketers. `,
          `We provide them with scalable solutions to upgrade their platform with the latest market trends. Our quick implementation and easy integration solution attract B2B marketers to opt for their automation solution to drive higher conversions. `,
          `In order to solve their integration issues, we offered pre-built integrations or APIs that allow for efficient data flow between systems that eliminates the need for manual data entry or cumbersome transfer processes.`,
        ],
      },
    ],
    image: ["hushly-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: "Products Interface",
    para: [
      `We also created an interface wherein the B2B marketers can look into the videos and articles provided for better understanding and platform adaptability. They can also filter it based on the project automation status i.e. ongoing, completed and archived.`,
    ],
    content: {
      image: ["hushly-projectone.webp"],
      imageAlt: 'Product Page of MarTech Platform',
      imageTitle: 'Products',
      text: [
        `We created this screen to show the step-by-step process of no-code test automation along with the preview for better user understanding and experience. Users can also customise their platform based on their requirements.`,
      ],
    },
  };

  const projectData2 = {
    title: `Mobile Version `,
    para: [
      `In order to improve the accessibility of the platform’s solutions, we created a mobile version for their prospective B2B marketers. `,
    ],
    content: {
      image: ["hushly-projecttwo.webp"],
      imageAlt: 'Mobile Responsive of MarTech Platform',
      imageTitle: 'Featured Product',
    },
  };

  const projectData3 = {
    title: "Guide View",
    para: [
      `This screen shows the full view of the guide that will help their marketers to look into specific topics to make further decisions for improved conversions.`,
    ],
    content: {
      image: [
        "hushly-projectthree.webp",
        "hushly-projectthree-2.webp",
        "hushly-projectthree-3.webp",
      ],
      firstAlt: 'UI Screen of product list page',
      firstTitle: 'Product List ',
      secondAlt: 'Download or View products',
      secondTitle: 'Product Overview',
      thirdAlt: 'UI Screen to View and learn about the product',
      thirdTitle: 'View Product',
    },
  };

  const conclusionData = [
    {
      para: [
        `Being a MarTech Automation Solution provider, Hushly has to adopt and implement new marketing techniques to assist their clients (B2B marketers) in improved conversion and offer enhanced customer experience.`,
        `However, they had several challenges to adapt to the market trends and offering scalable automation solutions.`,
        `We transformed their platform with respect to the new-aged marketing techniques and offered solutions such as seamless integration, better optimization, quick implementation of the up-gradation, etc. so that it aligns with their business goals.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={18} />
      </div>
    </Layout>
  );
};

export default Hushly;

export const Head = () => (
  <Seo title="Hushly- B2B MarTech Platform Case Study |Octet Design Studio" description="A responsive website for which we conducted UX consultation, UI UX Designing & Design System Engineering to increase conversions for B2B marketers." />
)